import React from 'react';
import IconGitHub from '../images/icon _GitHub.svg';
import IconLinkdin from '../images/icon_linkedin.svg';
import styled from 'styled-components';

const Social = styled.div`
    position:absolute;
    top:50px;
    right:60px;
    display:flex;
    flex-direction:column;
    gap:30px;
    align-items:center;

    img{
        width: 30px; 
        height: auto; 
    }

    div{
        width:1px;
        height:300px;
        background:linear-gradient(
            180deg,
            #08b6f9 0%,
            #6c56ef 33.57%,
            #1306dd 65.86%,
            #aa0eb2 100%
        );
    }

    @media(max-width:1024px){
        display:none;
    }

`;

export default function SocialMedia(){
    return(
        <Social>
        <div/>
        <a href="https://www.linkedin.com/in/bei-li-9853201a3/" target="_blank" rel="noopener noreferrer">
            <img src={IconLinkdin} alt='linkedin'/>
        </a>
        <a href="https://github.com/AlveraBei" target="_blank" rel="noopener noreferrer">
            <img src={IconGitHub} alt='github'/>
        </a>
        </Social>

    );
} 