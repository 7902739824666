import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const tiltAnimation = keyframes`
0% {
  transform: translateY(0) translateX(0);
}
30% {
  transform: translateY(100px) translateX(0px);
}
60% {
  transform: translateY(0) translateX(0);
}
80% {
  transform: translateY(50px) translateX(0px);
}
100% {
  transform: translateY(0) translateX(0);
}
`;


const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  top: 80px;
  margin-left: 150px;
  animation: ${fadeIn} 6s ease-in-out;

  h1 {
    font: bold 50px 'Spline Sans Mono', monospace;
    margin: 0;
    max-width: 500px;
    color: white;
  }

  p {
    font: normal 16px/1.5 "Unbounded", sans-serif;
    max-width: 380px;
    color: rgba(100, 222, 79,0.9);

  }

  h2 {
    margin: 0 0 0 180px;
    font: bold 13px 'Unbounded', monospace;
    color: #fa87e9;
  }


  div {
    z-index:-1;
    margin: 0 0 0 100px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      #08b6f9 0%,
      #6c56ef 33.57%,
      #1306dd 65.86%,
      #aa0eb2 100%
    );
    animation: ${tiltAnimation} 3s ease-in-out infinite;
  }

  @media screen and (max-width: 768px) {
    display: none; 
}
  }
`;

export default function HomePageContent() {
  const contentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const duration = Math.min(scrollTop * 0.1, 3); //       contentRef.current.style.transitionDuration = `${duration}s`; 
      contentRef.current.style.transform = `translateY(-${scrollTop * 0.15}px)`;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Content ref={contentRef}>
      <div />
      <h1>Work</h1>
      <p>BASED IN NEDERLAND</p>
    </Content>
  );
}
