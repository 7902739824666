import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import VideoBackground from '../components/VideoBackground';

const AboutPageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const Wave = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1; /* 确保文字位于视频之上 */
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AnimatedText = styled.div`
  padding:10px 15px;
  background-color:rgba(71, 70, 83 ,0.5) ;
  animation: ${fadeIn} 10s ease-out; 
  opacity: ${props => props.showText ? '1' : '0'}; 
  overflow: hidden;
  text-align: left; 
  position: absolute; 
  width: 40%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  p {
    font: normal 18px/1.5 "Unbounded", sans-serif;
    color: rgba(255, 255, 255);
    
    @media screen and (max-width: 768px) {
      font: normal 14px/1.5 "Unbounded", sans-serif;
  }
  }
  
  @media screen and (max-width: 768px) {
    padding:5px 10px;
    width: 60%;
    top: 40%;
  }

`;

export default function AboutPage() {
  const [text, setText] = useState('');
  const [showText, setShowText] = useState(false);
  

  useEffect(() => {
    const content = "Hi there! I'm Bei, a student at HU, juggling my roles as a developer and illustrator. I'm passionate about crafting clean code and bringing ideas to life through vibrant visuals. Join me as I bridge the gap between technology and art, creating immersive experiences that engage and inspire.";

    let currentIndex = 0;
    setShowText(true);
    const interval = setInterval(() => {
      if (currentIndex <= content.length) {
        
        setText(content.slice(0, currentIndex));
        currentIndex++; // 将 currentIndex 移到这里确保每次间隔函数都会增加它的值
      } else {
        clearInterval(interval); // 清除间隔
         // 打字完成后显示完整文字
      }
    }, 80); // 打字速度 (毫秒)

    return () => {
      clearInterval(interval);
      // setShowText(true); // 确保在卸载组件时不再显示文字
    };
  }, []); // useEffect仅运行一次，无需依赖

  return (
    <div id="about">
      <VideoBackground>
        <AnimatedText showText={showText}>
          <p>{text}</p>
        </AnimatedText>
      </VideoBackground>
      <AboutPageWrapper>
        <Wave>
          {/* 可以加入一些波浪效果 */}
        </Wave>
        {/* 在此处插入其他内容 */}
      </AboutPageWrapper>
    </div>
  );
}
