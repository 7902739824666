import React from 'react';
import styled from 'styled-components';

const VideoBackground = ({ videoSource, children }) => {
    return (
        <Container>
            <Video autoPlay muted loop>
            
                <source src={require(`../images/backgroundvideo.mp4`)} type="video/mp4" />
                Your browser does not support the video tag.
            </Video>
            {children}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
`;

export default VideoBackground;
