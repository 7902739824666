import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProjectKapper from '../images/kapper.png';
import ProjectZonnepanelen from '../images/zonnepanelen.png';
import ProjectASR from'../images/ASR.png';
import ProjectCAP from'../images/CAP.png';
import ProjectEcommerce from'../images/Ecommerc.png';
import styled from 'styled-components';
import WorkTitle from '../components/WorkTitle';
import Spline from '@splinetool/react-spline';


const Container = styled.div`
    position: relative; 
    display: flex;
    flex-direction: row;
    padding: 40px 0 0 20px;
    margin: 50px auto; /* 左右自动居中 */
    max-width: 80%; /* 屏幕宽度为80% */
    min-height: calc(100vh - 100px);
    border-top: 1px solid rgba(255, 255, 255, 0.2); 
    // background: linear-gradient(147deg, rgba(43,87,106,0.1) 0%, rgba(76,84,169,0.3) 52%, rgba(252,70,107,1) 100%);
    // overflow: hidden; 

    @media screen and (max-width: 768px) {
          
                display: flex;
              
        }
`;

const ProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0;
    color: #F2F2F2;
    cursor: pointer; /* 添加指针样式 */
    font: normal 15px/1.5 "Unbounded", sans-serif;
    

    &:hover {
        h3 {
            color: #45F254  ; // 设置鼠标悬停时的字体颜色
            transform: scale(1.1);
            text-decoration: underline; // 添加下划线效果
            transition: all 0.3s ease-in-out;
            
        }
    }

    @media screen and (max-width: 768px) {
        display:none;
    }
`;

const Image = styled.img`
    margin:40px 0 0 0;
    width: 80%;
    height: auto;
    border-radius: 8px ;
`;

const TextContainer = styled.div`
    padding: 0;

    h3 {
        font-size: 1.5em; /* 调整字体大小 */
        margin-bottom: 10px; /* 添加一些底部间距 */
    }
    
`;

const SideBox = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;  
    background-color: rgba(231,52,134,0);
    margin-left: auto; /* 将其推到右边 */
    border-radius: 8px 0 0 15px; /* 圆角边框 */
    
    
    h3, h4, p {
        position: relative; /* 确保文字元素相对于 SideBox 定位 */
        z-index: 2; /* 确保文字在伪元素上面 */
        color: rgba(255,255,255,0.7) ;
        font: normal 15px/1.5 "Unbounded", sans-serif;
    }
    h1{
        color: rgba(69, 242, 84,0.7);
        font: bold 25px 'Unbounded', monospace;

    }

    @media screen and (max-width: 768px) {
        position: static;
        width: 100%;
        border-radius: 0;
        margin:0;
        padding: 20px;
        display: block; /* 让内容在缩小屏幕时显示 */
    }

`;

function ProjectPage() {

     const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    handleResize(); // 初始化状态
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

    const projects = [
        {
            id: 1,
            title: "VR-House on fire",
            subtitle: "Technology Stack:Unity, C#",
            description: "The virtual reality (VR) project simulating a house fire is developed by ASR Insurance Company. Its primary objective is to explore how customers' decision to purchase insurance may be influenced by experiencing the process of a house fire in a VR environment. My main responsibility in this project is the construction of the house.",
            imageUrl: ProjectASR,
            url: "#"
        },
        {
            id: 2,
            title: "AI generation",
            subtitle: "Technology Stack: React, Electron",
            description: "This is an AI exploration project from Capgemini's innovation department. My team and I built a collection of AI tools for internal company demo use, enabling sales to easily display different AI demos to clients. I was responsible for building all the frontend in this project.",
            imageUrl: ProjectCAP,
            url: "#"
        },
        {
            id: 3,
            title: "Zonnepanelen",
            subtitle: "Technology Stack: Html, Css, JS, Figma",
            description:<div>This is my personal project, where the employer requested the design and building of a simple, low-maintenance static website for promotional purposes. <br/>Check the web <a href='https://zonnepanelen-middennederland.nl/'>here</a>.</div> ,
            imageUrl: ProjectZonnepanelen,
            url: "#"
        },
        {
            id: 4,
            title: "E-commerce",
            description:<div>E-commerce is my second school project, where I worked with a group pair to build a simple e-commerce website for learning purposes.<br/> <br/>  My main tasks were website design, database design and back-end building. I designed the homepage, package page, user login and dashboard at Figma. The rest of the team was responsible for designing the product pages and building the front-end. I worked on the back-end, using Express.js to add, delete, and modify data.
            <br/> <br/> Currently this site is no longer openable because the site was previously running on the school's server and now all the data has been cleared. 
            <br/>But you can check my design <a href='https://www.figma.com/proto/nZqF8Gpzc9YOzIn8u2rZti/e-commerce?t=G6qaIGyIhXiXRR9m-1&scaling=scale-down&page-id=0%3A1&node-id=127-1131&starting-point-node-id=247%3A854&show-proto-sidebar=1'>here.</a></div>,
            subtitle: "Technology Stack: Node.js. MySQL. ERD. Figma",
            imageUrl:ProjectEcommerce,
            url: "#"
        },
        {
            id: 5,
            title: "Kapper Marjolein",
            description: <div>The barber's website was my first school project and I built a simple static website with four other students . In this project I learned basic CSS and HTML. <br/> <br/> Currently this site is no longer openable because the site was previously running on the school's server and now all the data has been cleared.
            <br/>But you can check my design <a href='https://www.figma.com/proto/8QFtMHkLasz8AD1HMqhZ6Z/Marjolein?node-id=1-3&t=njafwhfSX5GhjLmU-1&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=1%3A2'>here.</a></div>,
            subtitle: "Technology Stack: HTML. CSS. Figma",
            imageUrl: ProjectKapper,
            url: "#"
        }
    ];
    const [selectedProject, setSelectedProject] = useState(projects[0]);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
    };

    return (
        <div id="projects">

       {/* <WorkTitle/> */}
        <Container>

            <div>
                {projects.map((project) => (
                    <ProjectContainer key={project.id} onMouseEnter={() => handleProjectClick(project)}>
                        <h3>{project.title}</h3>
               
                    </ProjectContainer>
                ))}
            </div>
            {isSmallScreen ? (
                
        <SideBox>
            <h1>Project</h1>
          {projects.map((project) => (
            <div key={project.id} style={{ display: 'flex', flexDirection: 'column' }}>
              <Image src={project.imageUrl} alt="Project Image" />
              <TextContainer>
                <h3>{project.title}</h3>
                <h4>{project.subtitle}</h4>
                <p>{project.description}</p>
              </TextContainer>
            </div>
          ))}
        </SideBox>
      ) : (
        <SideBox>
          {selectedProject && (
            <>
              <Image src={selectedProject.imageUrl} alt="Project Image" />
              <TextContainer>
                <h3>{selectedProject.title}</h3>
                <h4>{selectedProject.subtitle}</h4>
                <p>{selectedProject.description}</p>
              </TextContainer>
            </>
          )}
        </SideBox>
      )}
        </Container>
        <Spline scene="https://prod.spline.design/VZvO23meTrNG3VJH/scene.splinecode" />
        </div>
    );
}

export default ProjectPage;
