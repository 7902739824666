import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const WorkTitleContainer = styled.div`
  color:rgba(134, 156, 250,0.9);
  margin:80px 150px;
  font-size: 18px;
  // font-weight: bold;
  text-align: right;
  transition: opacity 0.5s ease-in-out;
  white-space: pre-line; /* 设置换行 */

  @media screen and (max-width: 768px) {
    display: none; 
}
`;

export default function WorkTitle({ showText }) {
  return (
    <WorkTitleContainer show={showText}>
      {'Work\n'}
      {'life\n'}
      {'Balance\n'}
      {'&\n'}
      {'BJJ \n'}
    </WorkTitleContainer>
  );
};
