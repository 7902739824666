import './App.css';
import HomePage from './pages/Home';
import ProjectPage from './pages/Project';
import AboutPage from './pages/About';
import WorkTitle from './components/WorkTitle';
import HomePageContent from './components/Content';


function App() {
  return (
    <div className="App">
      <HomePage id="home"/>
      <AboutPage id="about"/>
      <WorkTitle /> 
       <HomePageContent />
      <ProjectPage id="projects"/>
      

      
    </div>
  );
}

export default App;


