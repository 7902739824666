import React, { useRef, useEffect } from 'react';
import Animation from '../components/Splinetool';
import Nav from '../components/Nav';
import SocialMedia from '../components/SocialMedia';
import styled, { keyframes } from 'styled-components';


const Hero = styled.div`
  font: bold 65px 'Unbounded', monospace;
  margin: 0 auto;
  color: rgba(96, 214, 77, 0.8);
  width: 100%;
  text-align: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    font: bold 45px 'Unbounded', monospace;
  }
`;

const TextContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;

`;

// 使用 keyframes 定义动画
const marquee = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const MarqueeText = styled.div`
  display: inline-block;
  animation: ${marquee} 20s linear infinite; /* 调整动画持续时间 */
`;

function HomePage() {
  const textRef = useRef(null);

  useEffect(() => {
    const node = textRef.current;
    const parentWidth = node.parentNode.offsetWidth;
    const textWidth = node.offsetWidth;
    const animationDuration = (textWidth / parentWidth) * 10; // 调整动画持续时间
    node.style.animationDuration = `${animationDuration}s`;
  }, []);

  return (
    <div className="homepage" id="home">
      <header>
        <Nav />
      </header>
      <Animation />
      <SocialMedia />
      <Hero>
        <TextContainer>
          <MarqueeText ref={textRef}>
            DEVELOPER & DESIGNER BEI LI &nbsp; DEVELOPER & DESIGNER BEI LI &nbsp; DEVELOPER & DESIGNER BEI LI
          </MarqueeText>
        </TextContainer>
      </Hero>
  
    </div>
  );
}

export default HomePage;
