import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    background:rgba(0,0,0,0.2);
    border:0px;
    font-size:16px;
    padding:12px 30px;
    border-radius:14px;
    border:1px solid rgba(255,255,255,0.1);
    color:white;
    max-width:280px;
    backdrop-filter:blur(20px);

    display:flex;
    gap:12px;
    juistify-content:center;
    align-items:center;

    // &:hover {
    //     border: 1px solid rgba(255, 255, 255, 0.2);
    }
`;

export default Button;