import React from 'react';
import styled from 'styled-components';
import logo from '../images/logo.svg';
import Button from './Button';


const NavStyle = styled.ul`
    display:flex;
    gap:30px;
    align-items:center;
    margin:0 ;
    padding:0;
    position:fixed;
    top:2%;
    z-index:999;
    left: 50%;
    transform: translateX(-50%);
    

    li{
        list-style:none;
        margin:0;

        a{
            text-decoration:none;
            font: normal 15px/1.5 "Unbounded", sans-serif;
            color:white;
            padding:8px 20px;
            border:1px splid rgba(255,255,255,0);
            transition:1s;
            border-radius:14px;

            &:hover{
                border:1px solid #45F254;
                color:#45F254;
                
            }
        }
        img{
            width: 70px; 
            height: auto; 
            padding:8px 100px;
        }

        
        
        
        
        }
        @media(max-width:800px){
            > li {
                display: none;
            }


                > img {
                    display: block; /* 保持 logo 可见 */
                    width: 30px;
                    height: auto; 
                    padding:0 50px;
                }
            }
        
            
        }
    }




`;
export default function Nav(){
    return(
        <NavStyle>
            {/* <li><img src={logo} alt='logo'/></li> */}
            <li><a href='#home'>Home</a></li>
            <li><a href='#about'>About</a></li>
            <li><a href='#projects'>Project</a></li>
            <li><a href="mailto:alveralibei@gmail.com">Contact</a></li>
        </NavStyle>
    );

}

